<template>
  <li
    v-if="props.data.ft"
    class="flex flex-col items-center justify-center text-xs md:text-sm lg:text-base md:w-1/3 md:px-8 py-0.5 border-darkblue/20"
  >
    <div class="w-full flex items-center justify-center p-1">
      <a v-if="props.data.ft" :href="data.proof" target="_blank"
        ><img :src="data.proof" class="w-full h-full"
      /></a>
    </div>
  </li>
</template>

<script setup>
import { computed, ref } from "vue";
import { useConfigStore } from "@/stores/config";

const props = defineProps({
  data: Object,
  index: Number,
});

const configStore = useConfigStore();

const lose = require("@/assets/lose.png");
const win = require("@/assets/win.png");
const ins = require("@/assets/ins.png");

const even = computed(() => (props.index % 2 === 0 ? true : false));

const ftSrc = computed(() => {
  return props.data.ft ? win : lose;
});
</script>
