<template>
    <div>
        <a :href="props.to" target="_blank">
            <base-image
            :imgClass="props.imgClass"
            :src="props.imgSrc"
            :alt="props.alt"
            :width="width > breakpoint.xl ? '180px' : '90px'"
            :height="width > breakpoint.xl ? '60px' : '30px'"

            >
            
            </base-image>
        </a>
    </div>
</template>

<script setup>

import { useConfigStore } from '@/stores/config';
import { computed, ref } from 'vue'



const configStore = useConfigStore()

const vips = computed(()=> archiveStore.getVips)

const breakpoint = computed(()=> configStore.getWindowBreakpoint)
const width = computed(()=> configStore.getWindowWidth)
  
    const props = defineProps({
        imgSrc:String,
        imgClass:{type:String, default:''},

        to:String,
    })

 
    // const imageWidth = computed(()=>{
    //     if(width < breakpoint.md) return '90px'
    //     if(width < breakpoint.lg) return '120px'
    //     return '180px'
    // })    

    // const imageHeight = computed(()=>{
    //     if(width < breakpoint.md) return '30px'
    //     if(width < breakpoint.lg) return '40px'
    //     return '60px'
    // })    


  
</script>

<style>
    .banner {
        height:30px;
        width:90px;
    }
</style>