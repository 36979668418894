

<template>

    <svg :class="classes" class="relative" :width="props.size" :height="props.size" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke="currentColor" :stroke-width="+props.thickness" stroke-miterlimit="10"></circle>
        <circle v-if="props.shade" class="absolute top-0 opacity-10" cx="50" cy="50" r="20" fill="none" stroke="currentColor" :stroke-width="props.thickness" stroke-miterlimit="10"></circle>
    </svg>

</template>

<script setup>


    import { computed } from 'vue';
    const props = defineProps({

        
        thickness:{
            type:[Number, String],
            default:5
        },
        color:{
            type:String,
        },
        size:{
            type:[Number, String],
            default:'1em'
        },
        shade:{
            type:Boolean
        }

    });

    

    const classes = computed(()=>
        'spinner spinner-body' + (props.color ? ` text-${props.color}` : '')
    )



</script>

<style scoped>
    .spinner {
        vertical-align: middle;
    }

    .spinner-body {
        animation: spin 2s linear infinite;
        transform-origin: center center;

    }

    .spinner-body .path{
         stroke-dasharray:1,200;
         stroke-dashoffset:0;
         animation:body-dash 1.5s ease-in-out infinite
    }

    @keyframes spin {
        0% {
            transform:rotate3d(0,0,1,0)
        }
        25% {
            transform:rotate3d(0,0,1,90deg)
        }
        50% {
            transform:rotate3d(0,0,1,180deg)
        }
        75% {
            transform:rotate3d(0,0,1,270deg)
        }
        to {
            transform:rotate3d(0,0,1,359deg)
        }
    }

    @keyframes body-dash {
    0% {
        stroke-dasharray:1,200;
        stroke-dashoffset:0
    }
    50% {
        stroke-dasharray:89,200;
        stroke-dashoffset:-35px
    }
    to {
        stroke-dasharray:89,200;
        stroke-dashoffset:-124px
    }
}

</style>