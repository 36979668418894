<template>
  <li
    v-if="configStore.getWindowWidth > configStore.getWindowBreakpoint.md"
    class="flex h-[42px] items-center justify-center text-xs md:text-sm lg:text-base py-0.5 border-b-[1px] border-darkblue/20"
    :class="!even ? 'bg-darkblue/10' : ''"
  >
    <div class="w-2/12 flex items-center justify-center p-1">
      {{ data.date }}
    </div>
    <div class="w-1/12 flex items-center justify-center p-1">
      {{ data.time }}
    </div>
    <div class="w-1/12 flex items-center justify-center p-1">
      {{ data.league }}
    </div>
    <div class="w-2/12 flex items-center justify-center p-1">
      {{ data.home }}
    </div>
    <div class="w-2/12 flex items-center justify-center p-1">
      {{ data.away }}
    </div>
    <div class="w-1/12 flex items-center justify-center p-1">
      {{ data.pick }}
    </div>
    <div class="w-1/12 flex items-center justify-center p-1">
      {{ data.odd }}
    </div>
    <div class="w-1/12 flex items-center justify-center p-1">
      {{ data.result }}
    </div>
    <div class="w-1/12 flex items-center justify-center p-1">
      <a v-if="props.data.ft" :href="data.proof" target="_blank"
        ><img :src="ins" class="w-full h-full"
      /></a>
    </div>
    <div class="w-1/12 flex items-center justify-center p-1">
      <img :class="props.data.ft ? 'h-5 w-5' : 'h-3 w-3'" :src="ftSrc" />
    </div>
  </li>
  <li
    class="flex items-center text-xs md:text-sm lg:text-base py-1 px-2 space-x-2 border-b-[1px] border-darkblue/20"
    v-else
  >
    <div class="w-4/12 space-y-[0.125rem]">
      <div class="text-slate-400 flex space-x-1">
        <span>{{
          configStore.getWindowWidth > 360 ? data.date : data.date.slice(0, 5)
        }}</span>
        <span v-if="configStore.getWindowWidth > 560">{{ data.time }}</span>
      </div>
      <div class="flex">{{ data.league }}</div>
    </div>
    <div class="w-4/12 space-y-[0.125rem]">
      <div class="flex items-center space-x-2">
        <span v-if="configStore.getWindowWidth > 360" class="text-slate-400">{{
          result.length > 0 && result[0] ? result[0] : ""
        }}</span
        ><span>{{ data.home }}</span>
      </div>
      <div class="flex items-center space-x-2">
        <span v-if="configStore.getWindowWidth > 360" class="text-slate-400">{{
          result.length > 0 && result[1] ? result[1] : ""
        }}</span
        ><span>{{ data.away }}</span>
      </div>
    </div>
    <div class="w-3/12 space-y-[0.125rem]">
      <a
        class="flex flex-col items-center justify-center"
        :href="data.proof"
        target="_blank"
      >
        <img :src="ins" class="w-auto h-8" />
      </a>
    </div>
    <div class="w-1/12 flex items-center justify-center">
      <img :class="props.data.ft ? 'h-4 w-4' : 'h-2 w-2'" :src="ftSrc" />
    </div>
  </li>
</template>

<script setup>
import { computed, ref } from "vue";
import { useConfigStore } from "@/stores/config";

const props = defineProps({
  data: Object,
  index: Number,
});

const configStore = useConfigStore();

const lose = require("@/assets/lose.png");
const win = require("@/assets/win.png");
const ins = require("@/assets/ins.png");

const even = computed(() => (props.index % 2 === 0 ? true : false));

const result = ref(props.data.result.split(":"));

const ftSrc = computed(() => {
  return props.data.ft ? win : lose;
});
</script>
