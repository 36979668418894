import {computed, onMounted, onBeforeUnmount, ref} from 'vue';
import { useConfigStore } from '@/stores/config';



export const useBreakpoints = function (){

    //import store
    const configStore = useConfigStore()

    let windowWidth = ref(window.innerWidth)

    const onWidthChange = () => {
        windowWidth.value = window.innerWidth
    }

    //Add event listener on component mount (Use it on root component once in the app)
    onMounted(()=>{
        window.addEventListener('resize', onWidthChange)
    })

    //Remove listener from event loop on root component unmount
    onBeforeUnmount(()=>{
        console.log('ON BEFORE UNMOUT FIRE');
        window.removeEventListener('resize', onWidthChange)
    })

    //this breakpoints will match tailwindcss breakpoints for easier usage
    //this are lower boundries
    const breakpoint = ref({
        sm: 640,
        md: 768,
        lg:1024,
        xl:1280,
        xxl: 1536
    })

    const type = computed(()=>{
        if(windowWidth.value < 640) return 'xs' // MOBILE
        if(windowWidth.value >=640 && windowWidth.value < 768) return 'sm'
        if(windowWidth.value >=768 && windowWidth.value < 1024) return 'md'
        if(windowWidth.value >=1024 && windowWidth.value < 1280) return 'lg'
        if(windowWidth.value >=1280 && windowWidth.value < 1536) return 'xl'
        if(windowWidth.value >= 1536) return '2xl'
    })

    const width = computed(()=> windowWidth.value)

    //add values to pinia
    configStore.setWindowWidth(width)
    configStore.setWindowBreakpoint(breakpoint)
    configStore.setWindowType(type)

}