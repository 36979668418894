<script setup>
import ArchiveItem from "@/components/ArchiveItem.vue";
import { useArchiveStore } from "@/stores/archive";
import { useConfigStore } from "@/stores/config";
import { computed, ref } from "vue";
import vip from "@/assets/data/vip.json";

const archiveStore = useArchiveStore();
const configStore = useConfigStore();

// const vips = computed(()=> archiveStore.getVips)
const vips = computed(() => vip);

const currentPage = ref(1);
const rowsPerPage = ref(40);

const startIndex = computed(() => (currentPage.value - 1) * rowsPerPage.value);
const endIndex = computed(() => startIndex.value + rowsPerPage.value);

const slicedVips = computed(() =>
  vips.value.slice(startIndex.value, endIndex.value)
);
const pageCount = computed(() =>
  Math.ceil(vips.value.length / rowsPerPage.value)
);

const handlePagination = (page) => {
  currentPage.value = page;
};

const activeDate = computed(() => archiveStore.getCurrentVip.date);
const activeTime = computed(() => archiveStore.getCurrentVip.time);
</script>

<template>
  <ul :key="vips">
    <li
      v-if="configStore.getWindowWidth > configStore.getWindowBreakpoint.md"
      class="flex items-center justify-center font-bold text-sm md:text-base lg:text-lg border-b-[1px] border-darkblue bg-darkblue text-white py-1"
    >
      <h3 class="w-2/12 flex items-center justify-center p-2">DATE</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">TIME</h3>
      <h3 class="w-2/12 flex items-center justify-center p-2">LEAGUE</h3>
      <h3 class="w-2/12 flex items-center justify-center p-2">HOME</h3>
      <h3 class="w-2/12 flex items-center justify-center p-2">AWAY</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">PICK</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">ODD</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">RESULT</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">FT</h3>
    </li>
    <li
      v-if="configStore.getWindowWidth > configStore.getWindowBreakpoint.md"
      class="flex items-center justify-center font-bold text-xs md:text-sm lg:text-base border-b-[1px] border-darkblue/70 bg-darkblue text-active py-1"
    >
      <h3 class="w-2/12 flex items-center justify-center p-2">
        {{ activeDate }}
      </h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">
        {{ activeTime }}
      </h3>
      <h3 class="w-2/12 flex items-center justify-center p-2 space-x-1">
        <span>ACTIVE</span> <base-spinner></base-spinner>
      </h3>
      <h3 class="w-2/12 flex items-center justify-center p-2">VALUE $</h3>
      <h3 class="w-2/12 flex items-center justify-center p-2">SUBSCRIBE</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">
        <base-spinner></base-spinner>
      </h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">
        <base-spinner></base-spinner>
      </h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">
        <base-spinner></base-spinner>
      </h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">
        <base-spinner></base-spinner>
      </h3>
    </li>
    <li
      v-else
      class="flex items-center text-xs py-1 px-2 space-x-2 text-active font-bold bg-darkblue border-b-[1px] border-darkblue/70"
    >
      <div class="w-4/12 space-y-[0.125rem]">
        <div class="flex space-x-1">{{ activeDate }}</div>
        <div class="flex">{{ activeTime }}</div>
      </div>
      <div class="w-4/12">
        <div class="flex items-center space-x-1">
          <span>SUBSCRIBE NOW </span
          ><base-spinner v-if="configStore.getWindowWidth > 500"></base-spinner>
        </div>
      </div>
      <div class="w-3/12 flex items-center justify-center">
        <div class="flex items-center space-x-1">
          <span>ACTIVE</span>
          <base-spinner v-if="configStore.getWindowWidth > 500"></base-spinner>
        </div>
      </div>
      <div class="w-1/12 flex items-center justify-center">
        <base-spinner></base-spinner>
      </div>
    </li>
    <archive-item
      v-for="(vip, idx) in slicedVips"
      :key="idx"
      :data="vip"
      :index="idx + 1"
    ></archive-item>
    <li
      class="flex justify-center items-center min-h-[43px] font-bold text-sm md:text-base lg:text-lg border-b-[1px] border-darkblue/70 bg-darkblue py-1"
    >
      <div v-if="pageCount > 1" class="flex justify-center items-center p-2">
        <button
          v-if="currentPage > 1"
          class="px-1"
          @click="handlePagination(currentPage - 1)"
        >
          <img class="h-4" :src="require('../assets/left-chevron.png')" />
        </button>
        <button
          v-for="index in pageCount"
          :key="index"
          :class="{ 'text-active': currentPage === index }"
          class="px-1"
          @click="handlePagination(index)"
        >
          {{ index }}
        </button>
        <button class="px-1" @click="handlePagination(currentPage + 1)">
          <img
            v-if="currentPage < pageCount"
            class="h-4 rotate-180"
            :src="require('../assets/left-chevron.png')"
          />
        </button>
      </div>
    </li>
  </ul>
</template>
