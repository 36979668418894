import {defineStore} from 'pinia'

export const useConfigStore = defineStore('config', {
    state:() => ({
        TIMEOUT_SEC:10,
        windowWidth:null,
        windowBreakpoint:null,
        windowType:null
    }),
    getters:{
        getTimeout:(state) => state.TIMEOUT_SEC,
        getWindowWidth:(state) => state.windowWidth,
        getWindowBreakpoint:(state) => state.windowBreakpoint,
        getWindowType:(state) => state.windowType,
    },
    actions:{
        setWindowWidth(payload){
            this.windowWidth = payload
        },
        setWindowBreakpoint(payload){
            this.windowBreakpoint = payload
        },
        setWindowType(payload){
            this.windowType = payload
        }
    }
})