import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import {createPinia} from 'pinia'


//UI components
import BaseSpinner from '@/UI/BaseSpinner.vue';
import BaseButton from '@/UI/BaseButton.vue';
import BaseDrawer from '@/UI/BaseDrawer.vue';
import BaseCard from '@/UI/BaseCard.vue';
import BaseSection from '@/UI/BaseSection.vue';
import BaseCardSection from '@/UI/BaseCardSection.vue';
import BaseImage from '@/UI/BaseImage.vue';
import BaseBanner from '@/UI/BaseBanner.vue';

//Creating Pinia instance
const pinia = createPinia()

const app = createApp(App)

app
.component('base-spinner', BaseSpinner)
.component('base-button', BaseButton)
.component('base-drawer', BaseDrawer)
.component('base-card', BaseCard)
.component('base-section', BaseSection)
.component('base-card-section', BaseCardSection)
.component('base-image', BaseImage)
.component('base-banner', BaseBanner)



app.use(router).use(pinia).mount('#app')
