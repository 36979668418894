<template>
    <Teleport :to="props.route">
        <slot name="out"></slot>
        <div class="drawer--root fixed transition-all inset-0" :class="props.open ? 'z-[1000]' : '-z-10'">
            <div class="backdrop--root inset-0 fixed flex items-center transition-all justify-center -z-10 "
                :class="isBackdrop"
                :style="[props.open ? {opacity:1} : {opacity:0}]"
            >   
                <aside ref="drawer" class="drawer h-full flex flex-col  outline-none z-[900] fixed overflow-y-auto shadow-lg" :class="drawerClass" 
                    :style="[props.open  ? { transform:'translateX(0px)'} : {transform:`translateX(${props.orientation === 'left' ? '-' : ''}${props.width})`}, {width:props.width} ]">
                        <div class="drawer--content flex flex-col w-full h-full">

                            <slot name="menu"></slot>

                            <slot></slot>    
                              
                        </div>
                </aside>
                <div 
                ref="notDrawer" @click="close" class="fixed w-full h-full" :class="props.orientation === 'right' ? `right-[${props.width}]` : `left-[${props.width}]`"
                :style="[props.orientation === 'right' ? {right:`${props.width}`} : '']"      
                ></div>
               
            </div>
        </div>
    </Teleport>
</template>

<script setup>
import {computed, ref} from 'vue'

    const props = defineProps({
        open:{
            type:Boolean,
            default:true
            
        },
        route:{
            type:String,
            default:'body'
        },
        backdrop:{
            type:[Boolean, String],
            default:false
        },
        orientation:{
            type: String,
            default:'left',
            validatior:(val) => ['left','right'].includes(val)
        },
        background:{
            type: String,
            default:'bg-white',
        },
        customClass:{
            type:String,
            default:''
        },
        width:{
            type:String,
            default:'300px'
        }


    });

    const emits = defineEmits(['close'])

    const drawer = ref(null)
    const notDrawer = ref(null)

    const isBackdrop = computed(()=> {
        if (props.backdrop === true) return 'bg-black/50'
        if (props.backdrop === false) return ''
        return props.backdrop
    })

    const drawerClass = computed(()=>{
        return props.background + ` drawer--${props.orientation} ` + props.customClass
    })

    const close = function(e){  
        emits('close')
    }


</script>

<style lang="scss" scoped>
 .drawer{
    transition: transform 400ms cubic-bezier(0,0,0.2,1);

    

    &--left{
        left:0;
        right: auto;
    }
    &--right{
        left:auto;
        right: 0;
    }
 }
</style>