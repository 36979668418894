
<template>
    <component :is="tag"  :class="classes">
        <slot></slot> 
    </component>
</template>

<script setup>
/** 
 * This will be regular HTML element
 * at the moment all card styling are done with tailwind
*/  

    import {computed, toRefs } from 'vue';
    const props = defineProps({
        /**
         * HTML tag to use 
         */
        tag:{
            type:String,
            default:"div",
            validator:(val)=>["","div", "section", "span", "form", "aside", "article"].includes(val),
        },
        horizontal:{
            type:Boolean,
        },

    });

    const {tag} = toRefs(props)


    const classes = computed(()=>

        'card__section' 
        + ` card__section--${(props.horizontal === true ? 'horiz flex flex-row flex-nowrap' : 'vert')}`  
    )



</script>

