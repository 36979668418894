<template>
    <button v-if="!props.link" @click="handleClick" :type="props.type" :disabled="loading" class="flex items-center justify-start hover:opacity-90 active:opacity-80  ease-in-out transition-all" :class="classes" >
       <div class="relative">
       <span v-if="loading" class=" top-2/4 left-2/4  absolute -translate-x-2/4 -translate-y-2/4 ">
           <base-spinner
           
           size="1.5em"
           ></base-spinner>
       </span>
       <span :class="loading ? 'opacity-0' : ''" class="flex items-center">
           <slot name="icon-left"></slot>
           <slot>
               {{label}}
           </slot>
           <slot name="icon-right"></slot>
       </span>
   </div>
   </button> 

</template>

<script setup>
   import {computed, ref, toRefs } from 'vue';
   const props = defineProps({
       type:{
           type:String,
           default:'button'
       },
       inline:{
           type:Boolean,
           default:false
       },
       label:{
           type:[Number, String],
       },
       flat:{
           type:Boolean,
       },
       round:{
           type:String,
       },
       outline:{
           type:Boolean,
       },

       loading:{
           type:Boolean,
           default:null
       },
       padding:{
           type:String,

       },
       color: {
           type:String,
           default:'hill-blue'
       },
       textColor: {
           type:String,
           default:'white'
       },
       size:{
           type:String,
           default:'md'
       }


   });
 
   const isLink = computed(()=> props.link)

   const padding = {
       none: 'p-0', // 0
       xs: 'p-1', // 0.25rem ->4px
       sm: 'p-2', // 0.5rem -> 8px
       md: 'p-4', // 1rem -> 16px
       lg: 'p-6', // 1.5rem -> 24px
       xl: 'p-8', // 2rem -> 32px
   }


   const rounds = {
       full:'rounded-full',
       none: 'rounded-none', // 0
       sm: 'rounded-sm', 
       base: 'rounded', 
       md: 'rounded-md', 
       lg: 'rounded-lg', 
       xl: 'rounded-xl', 
       xxxl: 'rounded-3xl', 
   }


   const sizes = {
       xs: 'text-xs', // 0.75rem ->12px
       sm: 'text-sm', // 0.875rem -> 14px
       md: 'text-base', // 1rem -> 16px
       lg: 'text-lg', // 1.125rem -> 18px
       xl: 'text-2xl', // 1.25rem -> 20px
       xxl: 'text-2xl', // 1.5rem -> 24px
   }

  const classes = computed(()=>
       'btn' 
       
       + (props.color && !props.outline && !props.inline ? ` bg-${props.color}` : '')
       + (props.textColor  ? ` text-${props.textColor}` : '')
       + (props.outline && !props.inline ? ` bg-transparent border border-${props.color} text-${props.color}` : '')
       + (props.size ? ` ${sizes[props.size]}` : '')
       + (props.padding ? ` ${padding[props.padding]}` : '')
       + (props.flat === true ? ' drop-shadow-none' : ' drop-shadow')
       + (props.round && !props.inline?  ` ${rounds[props.round]}` : '')


   )




</script>
