

<template>
    <component :is="tag"  :class="classes">
        <slot></slot> 
    </component>
</template>

<script setup>
/** 
 * This will be regular HTML element
 * at the moment all card styling are done with tailwind
*/  

    import {computed, toRefs } from 'vue';
    const props = defineProps({
        /**
         * HTML tag to use 
         */
        tag:{
            type:String,
            default:"div",
            validator:(val)=>["","div", "section", "span", "form", "aside", "article"].includes(val),
        },
        square:{
            type:Boolean,
        },
        flat:{
            type:Boolean,
        },
        bordered:{
            type:Boolean,
        },
        raw:{
            type:Boolean,
        }
        /**
         * Notify component that dark mode is off/on
         */

    });

    const {tag} = toRefs(props)


    const classes = computed(()=>

        props.raw ? 'card' 
        : 'card'
        + (props.bordered === true ? ' border border-slate-200' : ' border-none') 
        + (props.square === true ? ' rounded-none' : ' rounded-md')
        + (props.flat === true ? ' drop-shadow-none' : ' drop-shadow')
    
    )



</script>

