<script setup>
import ArchiveItem from "@/components/ArchiveItem.vue";
import { useArchiveStore } from "@/stores/archive";
import { useConfigStore } from "@/stores/config";
import { computed, ref } from "vue";
import free from "@/assets/data/free.json";

const archiveStore = useArchiveStore();
const configStore = useConfigStore();

//const freeTips = computed(()=> archiveStore.getFreeTips)

const freeTips = computed(() => free);

const currentPage = ref(1);
const rowsPerPage = ref(20);

const startIndex = computed(() => (currentPage.value - 1) * rowsPerPage.value);
const endIndex = computed(() => startIndex.value + rowsPerPage.value);

const pageCount = computed(() =>
  Math.ceil(arhivedFreeTips.value.length / rowsPerPage.value)
);

const arhivedFreeTips = computed(() =>
  freeTips.value.filter((tip) => !tip.current)
);

const slicedFrees = computed(() =>
  arhivedFreeTips.value.slice(startIndex.value, endIndex.value)
);

const currentFreeTips = computed(() =>
  freeTips.value.filter((tip) => tip.current)
);

const activeDate = computed(() => archiveStore.getCurrentVip.date);
const activeTime = computed(() => archiveStore.getCurrentVip.time);

const handlePagination = (page) => {
  currentPage.value = page;
};
</script>

<template>
  <ul :key="freeTips">
    <li
      v-if="configStore.getWindowWidth > configStore.getWindowBreakpoint.md"
      class="flex items-center justify-center font-bold text-sm md:text-base lg:text-lg border-b-[1px] border-darkblue bg-darkblue text-white py-1"
    >
      <h3 class="w-2/12 flex items-center justify-center p-2">DATE</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">TIME</h3>
      <h3 class="w-2/12 flex items-center justify-center p-2">LEAGUE</h3>
      <h3 class="w-2/12 flex items-center justify-center p-2">HOME</h3>
      <h3 class="w-2/12 flex items-center justify-center p-2">AWAY</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">PICK</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">ODD</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">RESULT</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">FT</h3>
    </li>
    <li v-for="(tip, idx) in currentFreeTips" :key="idx">
      <div
        v-if="configStore.getWindowWidth > configStore.getWindowBreakpoint.md"
        class="flex items-center justify-center font-bold text-xs md:text-sm lg:text-base border-b-[1px] border-darkblue/70 bg-darkblue text-active py-1"
      >
        <h3 class="w-2/12 flex items-center justify-center p-2">
          {{ tip.date }}
        </h3>
        <h3 class="w-1/12 flex items-center justify-center p-2">
          {{ tip.time }}
        </h3>
        <h3 class="w-2/12 flex items-center justify-center p-2">
          {{ tip.league }}
        </h3>
        <h3 class="w-2/12 flex items-center justify-center p-2">
          {{ tip.home }}
        </h3>
        <h3 class="w-2/12 flex items-center justify-center p-2">
          {{ tip.away }}
        </h3>
        <h3 class="w-1/12 flex items-center justify-center p-2">
          {{ tip.pick }}
        </h3>
        <h3 class="w-1/12 flex items-center justify-center p-2">
          {{ tip.odd }}
        </h3>
        <h3 class="w-1/12 flex items-center justify-center p-2">
          <base-spinner></base-spinner>
        </h3>
        <h3 class="w-1/12 flex items-center justify-center p-2">
          <base-spinner></base-spinner>
        </h3>
      </div>

      <div
        v-else
        class="flex items-center text-xs md:text-sm lg:text-base py-1 px-2 space-x-2 border-b-[1px] border-darkblue/70 font-bold bg-darkblue text-active"
      >
        <div class="w-4/12 space-y-[0.125rem]">
          <div class="flex space-x-1">
            <span>{{
              configStore.getWindowWidth > 360 ? tip.date : tip.date.slice(0, 5)
            }}</span>
            <span v-if="configStore.getWindowWidth > 560">{{ tip.time }}</span>
          </div>
          <div class="flex">{{ tip.league }}</div>
        </div>
        <div class="w-4/12 space-y-[0.125rem]">
          <div class="flex items-center space-x-2">
            <span>{{ tip.home }}</span>
          </div>
          <div class="flex items-center space-x-2">
            <span>{{ tip.away }}</span>
          </div>
        </div>
        <div class="w-3/12 flex items-center justify-center">
          <div>{{ tip.pick }} at {{ tip.odd }}</div>
        </div>
        <div class="w-1/12 flex items-center justify-center">
          <base-spinner></base-spinner>
        </div>
      </div>
    </li>

    <archive-item
      v-for="(tip, idx) in slicedFrees"
      :key="idx"
      :data="tip"
      :index="idx + 1"
    ></archive-item>
    <li
      class="flex p-2 min-h-[43px] justify-center items-center font-bold text-sm md:text-base lg:text-lg border-b-[1px] border-darkblue/90 bg-darkblue py-1"
    >
      <!-- <h3 class="w-full flex items-center justify-end p-2 text-sky-300">
        &nbsp;v-if="configStore.getWindowWidth > configStore.getWindowBreakpoint.md"
      </h3> -->
      <div v-if="pageCount > 1" class="flex justify-center items-center p-2">
        <button
          v-if="currentPage > 1"
          class="px-1"
          @click="handlePagination(currentPage - 1)"
          arhivedFreeTips
        >
          <img class="h-4" :src="require('../assets/left-chevron.png')" />
        </button>
        <button
          v-for="index in pageCount"
          :key="index"
          :class="{ 'text-active': currentPage === index }"
          class="px-1"
          @click="handlePagination(index)"
        >
          {{ index }}
        </button>
        <button class="px-1" @click="handlePagination(currentPage + 1)">
          <img
            v-if="currentPage < pageCount"
            class="h-4 rotate-180"
            :src="require('../assets/left-chevron.png')"
          />
        </button>
      </div>
    </li>
  </ul>
</template>
